import React from "react";
import ReactDOM from "react-dom/client";
// import App from './App';
import WebPaleozoicEra from "./Paleozoic";

document.addEventListener("DOMContentLoaded", () => {
  const root = ReactDOM.createRoot(document.getElementById("app"));
  root.render(<WebPaleozoicEra />);
});

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(<Paleozoic />);
