import * as THREE from "three"; // 'https://cdn.jsdelivr.net/npm/three@0.118/build/three.module.js';
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js"; // 'https://cdn.jsdelivr.net/npm/three@0.118.1/examples/jsm/loaders/FBXLoader.js';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"; // 'https://cdn.jsdelivr.net/npm/three@0.118.1/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js"; 

import Component from "./Component.mjs";
import AIInput from "./AIInput.mjs";
import NPCFSM from "./NPCFSM.mjs";
import BasicCharacterControllerProxy from "./BasicCharacterControllerProxy.mjs";

class NPCController1 extends Component {
  constructor(params) {
    super();
    this._Init(params);
  }

  _Init(params) {
    this._params = params;
    this._decceleration = new THREE.Vector3(-0.0005, -0.0001, -5.0);
    this._acceleration = new THREE.Vector3(1, 0.25, 40.0);
    this._velocity = new THREE.Vector3(0, 0, 0);
    this._position = new THREE.Vector3();

    this._animations = {};
    this._input = new AIInput();
    // FIXME
    this._stateMachine = new NPCFSM(new BasicCharacterControllerProxy(this._animations));

    this._LoadModels();
  }

  InitComponent() {
    this._RegisterHandler('health.death', (m) => { this._OnDeath(m); });
    this._RegisterHandler('update.position', (m) => { this._OnPosition(m); });
  }

  _OnDeath(msg) {
    this._stateMachine.SetState('death');
  }

  _OnPosition(m) {
    if (this._target) {
      this._target.position.copy(m.value);
      this._target.position.y = 0;
    }
  }

  _LoadModels() {
    const loader = new GLTFLoader();
    const draco = new DRACOLoader();
    // draco.setDecoderPath('https://cdn.jsdelivr.net/npm/three@0.118.1/examples/js/libs/draco/');
    draco.setDecoderPath('./draco/');
    loader.setDRACOLoader(draco);
    loader.setPath('./paleozoic-resources/glb/npc/');
    loader.load(this._params.resourceName, (glb) => {
      this._target = glb.scene;
      this._target.scale.set(4.5, 4.5, 4.5);
      this._params.scene.add(this._target);
      this._target.position.copy(this._parent._position);

      this._target.traverse(c => {
        c.castShadow = true;
        c.receiveShadow = true;
      });

      const texLoader = new THREE.TextureLoader();
      const texture = texLoader.load('./paleozoic-resources/glb/npc/' + this._params.resourceTexture);
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;

      this._target.traverse(c => {
        c.castShadow = true;
        c.receiveShadow = true;
        if (c.material) {
          c.material.map = texture;
          c.material.side = THREE.DoubleSide;
        }
      });
      this._mixer = new THREE.AnimationMixer(this._target);

      const fbx = glb;

      const _FindAnim = (animName) => {
        for (let i = 0; i < fbx.animations.length; i++) {
          if (fbx.animations[i].name.includes(animName)) {
            const clip = fbx.animations[i];
            const action = this._mixer.clipAction(clip);
            return {
              clip: clip,
              action: action
            }
          }
        }
        return null;
      
      };

      this._animations['idle'] = _FindAnim('H_Roar');
      this._animations['walk'] = _FindAnim('H_Attack1_Dash');
      this._animations['run'] = _FindAnim('H_Attack1_Dash');
      this._animations['attack'] = _FindAnim('H_Bite2');

      this._stateMachine.SetState('idle');
    });
  }

  get Position() {
    return this._position;
  }

  get Rotation() {
    if (!this._target) {
      return new THREE.Quaternion();
    }
    return this._target.quaternion;
  }

  _FindIntersections(pos) {
    const _IsAlive = (c) => {
      const h = c.entity.GetComponent('HealthComponent');
      if (!h) {
        return true;
      }
      return h._health > 0;
    };

    const grid = this.GetComponent('SpatialGridController');
    const nearby = grid.FindNearbyEntities(100).filter(e => _IsAlive(e));
    const collisions = [];

    for (let i = 0; i < nearby.length; ++i) {
      const e = nearby[i].entity;
      const d = ((pos.x - e._position.x) ** 2 + (pos.z - e._position.z) ** 2) ** 0.5;

      // HARDCODED
      if (d <= 25) {
        collisions.push(nearby[i].entity);
      }
    }
    return collisions;
  }

  _FindPlayer(pos) {
    const _IsAlivePlayer = (c) => {
      const h = c.entity.GetComponent('HealthComponent');
      if (!h) {
        // this.sound.pause();
        return false;
      }
      if (c.entity.Name !== 'player') {
        // this.sound.pause();
        return false;
      }
      return h._health > 0;
    };

    const grid = this.GetComponent('SpatialGridController');
    const nearby = grid.FindNearbyEntities(200).filter(c => _IsAlivePlayer(c));

    if (nearby.length === 0) {
      return new THREE.Vector3(0, 0, 0);
    }

    const dir = this._parent._position.clone();
    dir.sub(nearby[0].entity._position);
    dir.y = 0.0;
    dir.normalize();

    return dir;
  }

  _UpdateAI(timeInSeconds) {
    const currentState = this._stateMachine._currentState;
    if (currentState.Name !== 'walk' &&
        currentState.Name !== 'run' &&
        currentState.Name !== 'idle') {
      return;
    }

    if (currentState.Name === 'death') {
      return;
    }

    if (currentState.Name === 'idle' ||
        currentState.Name === 'walk') {
      this._OnAIWalk(timeInSeconds);
    }
  }

  _OnAIWalk(timeInSeconds) {
    const dirToPlayer = this._FindPlayer();

    const velocity = this._velocity;
    const frameDecceleration = new THREE.Vector3(
        velocity.x * this._decceleration.x,
        velocity.y * this._decceleration.y,
        velocity.z * this._decceleration.z
    );
    frameDecceleration.multiplyScalar(timeInSeconds);
    frameDecceleration.z = Math.sign(frameDecceleration.z) * Math.min(
        Math.abs(frameDecceleration.z), Math.abs(velocity.z));

    velocity.add(frameDecceleration);

    const controlObject = this._target;
    // const _Q = new THREE.Quaternion();
    // const _A = new THREE.Vector3();
    const _R = controlObject.quaternion.clone();

    this._input._keys.forward = false;

    const acc = this._acceleration;
    if (dirToPlayer.length === 0) {
      return;
    }

    this._input._keys.forward = true;
    velocity.z += acc.z * timeInSeconds;

    const m = new THREE.Matrix4();
    m.lookAt(
        new THREE.Vector3(0, 0, 0),
        dirToPlayer,
        new THREE.Vector3(0, 1, 0));
    _R.setFromRotationMatrix(m);

    controlObject.quaternion.copy(_R);

    const oldPosition = new THREE.Vector3();
    oldPosition.copy(controlObject.position);

    const forward = new THREE.Vector3(0, 0, 1);
    forward.applyQuaternion(controlObject.quaternion);
    forward.normalize();

    const sideways = new THREE.Vector3(1, 0, 0);
    sideways.applyQuaternion(controlObject.quaternion);
    sideways.normalize();

    sideways.multiplyScalar(2*velocity.x * timeInSeconds);
    forward.multiplyScalar(2*velocity.z * timeInSeconds);

    const pos = controlObject.position.clone();
    pos.add(forward);
    pos.add(sideways);

    const collisions = this._FindIntersections(pos);
    if (collisions.length > 0) {
      this._input._keys.space = true;
      this._input._keys.forward = false;
      return;
    }

    controlObject.position.copy(pos);
    this._position.copy(pos);

    this._parent.SetPosition(this._position);
    this._parent.SetQuaternion(this._target.quaternion);
  }

  Update(timeInSeconds) {
    if (!this._stateMachine._currentState) {
      return;
    }

    this._input._keys.space = false;
    this._input._keys.forward = false;

    this._UpdateAI(timeInSeconds);

    this._stateMachine.Update(timeInSeconds, this._input);

    // HARDCODED
    if (this._stateMachine._currentState._action) {
      this.Broadcast({
        topic: 'player.action',
        action: this._stateMachine._currentState.Name,
        time: this._stateMachine._currentState._action.time,
      });
    }
    
    if (this._mixer) {
      this._mixer.update(timeInSeconds);
    }

  }
};






class NPCController2 extends Component {
  constructor(params) {
    super();
    this._Init(params);
  }

  _Init(params) {
    this._params = params;
    this._decceleration = new THREE.Vector3(-0.0005, -0.0001, -5.0);
    this._acceleration = new THREE.Vector3(1, 0.25, 40.0);
    this._velocity = new THREE.Vector3(0, 0, 0);
    this._position = new THREE.Vector3();

    this._animations = {};
    this._input = new AIInput();
    // FIXME
    this._stateMachine = new NPCFSM(new BasicCharacterControllerProxy(this._animations));

    this._LoadModels();
  }

  InitComponent() {
    this._RegisterHandler('health.death', (m) => { this._OnDeath(m); });
    this._RegisterHandler('update.position', (m) => { this._OnPosition(m); });
  }

  _OnDeath(msg) {
    this._stateMachine.SetState('death');
  }

  _OnPosition(m) {
    if (this._target) {
      this._target.position.copy(m.value);
      this._target.position.y = 0.35;
    }
  }

  _LoadModels() {
    // const loader = new FBXLoader();
    const loader = new GLTFLoader();
    const draco = new DRACOLoader();
    // draco.setDecoderPath('https://cdn.jsdelivr.net/npm/three@0.118.1/examples/js/libs/draco/');
    draco.setDecoderPath('./draco/');
    loader.setDRACOLoader(draco);
    loader.setPath('./paleozoic-resources/glb/npc/');
    // loader.setPath('../paleozoic-resources/fbx/npc/');
    loader.load(this._params.resourceName, (glb) => {
      this._target = glb.scene;
      this._target.scale.set(10, 10, 10);
      // this._target.scale.setScalar(0.07);
      this._params.scene.add(this._target);
      this._target.position.copy(this._parent._position);

      const texLoader = new THREE.TextureLoader();
      const texture = texLoader.load(
          './paleozoic-resources/glb/npc/' + this._params.resourceTexture);
          // url);
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;

      this._target.traverse(c => {
        if (c.isMesh) {
          c.castShadow = true;
          c.receiveShadow = true;
          if (c.material) {
            c.material.map = texture;
            c.material.needsUpdate=true;
            c.material.side = THREE.DoubleSide;
            // c.material.opacity = 1.0;
          }
        }
      });

      this._mixer = new THREE.AnimationMixer(this._target);

      const fbx = glb;
      const _FindAnim = (animName) => {
        for (let i = 0; i < fbx.animations.length; i++) {
          if (fbx.animations[i].name.includes(animName)) {
            const clip = fbx.animations[i];
            const action = this._mixer.clipAction(clip);
            return {
              clip: clip,
              action: action
            }
          }
        }
        return null;
      };

      this._animations['idle'] = _FindAnim('Idle');
      this._animations['walk'] = _FindAnim('Speed');
      this._animations['run'] = _FindAnim('Speed');
      this._animations['attack'] = _FindAnim('Bite');

      this._stateMachine.SetState('idle');
    });
    };

  get Position() {
    return this._position;
  }

  get Rotation() {
    if (!this._target) {
      return new THREE.Quaternion();
    }
    return this._target.quaternion;
  }

  _FindIntersections(pos) {
    const _IsAlive = (c) => {
      const h = c.entity.GetComponent('HealthComponent');
      if (!h) {
        return true;
      }
      return h._health > 0;
    };

    const grid = this.GetComponent('SpatialGridController');
    const nearby = grid.FindNearbyEntities(100).filter(e => _IsAlive(e));
    const collisions = [];

    for (let i = 0; i < nearby.length; ++i) {
      const e = nearby[i].entity;
      const d = ((pos.x - e._position.x) ** 2 + (pos.z - e._position.z) ** 2) ** 0.5;

      // HARDCODED
      if (d <= 30) {
        collisions.push(nearby[i].entity);
      }
    }
    return collisions;
  }

  _FindPlayer(pos) {
    const _IsAlivePlayer = (c) => {
      const h = c.entity.GetComponent('HealthComponent');
      if (!h) {
        return false;
      }
      if (c.entity.Name !== 'player') {
        return false;
      }
      return h._health > 0;
    };

    const grid = this.GetComponent('SpatialGridController');
    const nearby = grid.FindNearbyEntities(200).filter(c => _IsAlivePlayer(c));

    if (nearby.length === 0) {
      return new THREE.Vector3(0, 0, 0);
    }

    const dir = this._parent._position.clone();
    dir.sub(nearby[0].entity._position);
    dir.y = 0.0;
    dir.normalize();

    return dir;
  }

  _UpdateAI(timeInSeconds) {
    const currentState = this._stateMachine._currentState;
    if (currentState.Name !== 'walk' &&
        currentState.Name !== 'run' &&
        currentState.Name !== 'idle') {
      return;
    }

    if (currentState.Name === 'death') {
      return;
    }

    if (currentState.Name === 'idle' ||
        currentState.Name === 'walk') {
      this._OnAIWalk(timeInSeconds);
    }
  }

  _OnAIWalk(timeInSeconds) {
    const dirToPlayer = this._FindPlayer();

    const velocity = this._velocity;
    const frameDecceleration = new THREE.Vector3(
        velocity.x * this._decceleration.x,
        velocity.y * this._decceleration.y,
        velocity.z * this._decceleration.z
    );
    frameDecceleration.multiplyScalar(timeInSeconds);
    frameDecceleration.z = Math.sign(frameDecceleration.z) * Math.min(
        Math.abs(frameDecceleration.z), Math.abs(velocity.z));

    velocity.add(frameDecceleration);

    const controlObject = this._target;
    // const _Q = new THREE.Quaternion();
    // const _A = new THREE.Vector3();
    const _R = controlObject.quaternion.clone();

    this._input._keys.forward = false;

    const acc = this._acceleration;
    if (dirToPlayer.length === 0) {
      return;
    }

    this._input._keys.forward = true;
    velocity.z += acc.z * timeInSeconds * 2;

    const m = new THREE.Matrix4();
    m.lookAt(
        new THREE.Vector3(0, 0, 0),
        dirToPlayer,
        new THREE.Vector3(0, 1, 0));
    _R.setFromRotationMatrix(m);

    controlObject.quaternion.copy(_R);

    const oldPosition = new THREE.Vector3();
    oldPosition.copy(controlObject.position);

    const forward = new THREE.Vector3(0, 0, 1);
    forward.applyQuaternion(controlObject.quaternion);
    forward.normalize();

    const sideways = new THREE.Vector3(1, 0, 0);
    sideways.applyQuaternion(controlObject.quaternion);
    sideways.normalize();

    sideways.multiplyScalar(2*velocity.x * timeInSeconds);
    forward.multiplyScalar(2*velocity.z * timeInSeconds);

    const pos = controlObject.position.clone();
    pos.add(forward);
    pos.add(sideways);

    const collisions = this._FindIntersections(pos);
    if (collisions.length > 0) {
      this._input._keys.space = true;
      this._input._keys.forward = false;
      return;
    }

    controlObject.position.copy(pos);
    this._position.copy(pos);

    this._parent.SetPosition(this._position);
    this._parent.SetQuaternion(this._target.quaternion);
  }

  Update(timeInSeconds) {
    if (!this._stateMachine._currentState) {
      return;
    }

    this._input._keys.space = false;
    this._input._keys.forward = false;

    this._UpdateAI(timeInSeconds);

    this._stateMachine.Update(timeInSeconds, this._input);

    // HARDCODED
    if (this._stateMachine._currentState._action) {
      this.Broadcast({
        topic: 'player.action',
        action: this._stateMachine._currentState.Name,
        time: this._stateMachine._currentState._action.time,
      });
    }
    
    if (this._mixer) {
      this._mixer.update(timeInSeconds);
    }
  }
};

class NPCController3 extends Component {
  constructor(params) {
    super();
    this._Init(params);
  }

  _Init(params) {
    this._params = params;
    this._decceleration = new THREE.Vector3(-0.0005, -0.0001, -5.0);
    this._acceleration = new THREE.Vector3(1, 0.25, 40.0);
    this._velocity = new THREE.Vector3(0, 0, 0);
    this._position = new THREE.Vector3();

    this._animations = {};
    this._input = new AIInput();
    // FIXME
    this._stateMachine = new NPCFSM(new BasicCharacterControllerProxy(this._animations));

    this._LoadModels();
  }

  InitComponent() {
    this._RegisterHandler('health.death', (m) => { this._OnDeath(m); });
    this._RegisterHandler('update.position', (m) => { this._OnPosition(m); });
  }

  _OnDeath(msg) {
    this._stateMachine.SetState('death');
  }

  _OnPosition(m) {
    if (this._target) {
      this._target.position.copy(m.value);
      this._target.position.y = -120;
    }
  }

  _LoadModels() {
    const loader = new FBXLoader();
    loader.setPath('./paleozoic-resources/fbx/npc/');
    loader.load(this._params.resourceName, (glb) => {
      this._target = glb;
      // this._target = glb.scene;
      // this._target.scale.set(3, 3, 3);
      this._target.scale.setScalar(0.03);
      this._params.scene.add(this._target);
      this._target.position.copy(this._parent._position);

      const texLoader = new THREE.TextureLoader();
      const texture = texLoader.load('./paleozoic-resources/fbx/npc/' + this._params.resourceTexture);
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = true;

      this._target.traverse(c => {
        c.castShadow = true;
        c.receiveShadow = true;
        if (c.material) {
          c.material.map = texture;
          c.material.side = THREE.DoubleSide;
        }
      });

      
      this._mixer = new THREE.AnimationMixer(this._target);

      const fbx = glb;
      const _FindAnim = (animName) => {
        for (let i = 0; i < fbx.animations.length; i++) {
          if (fbx.animations[i].name.includes(animName)) {
            const clip = fbx.animations[i];
            const action = this._mixer.clipAction(clip);
            return {
              clip: clip,
              action: action
            }
          }
        }
        return null;
      };

      this._animations['idle'] = _FindAnim('AnomalocarisArmature|AnomalocarisArmature|AnomalocarisArmatu');
      this._animations['walk'] = _FindAnim('AnomalocarisArmature|AnomalocarisArmature|AnomalocarisArmatu');
      this._animations['run'] = _FindAnim('AnomalocarisArmature|AnomalocarisArmature|AnomalocarisArmatu');
      this._animations['attack'] = _FindAnim('AnomalocarisArmature|AnomalocarisArmature|AnomalocarisArmatu');

      this._stateMachine.SetState('idle');
      

    });
    };

  get Position() {
    return this._position;
  }

  get Rotation() {
    if (!this._target) {
      return new THREE.Quaternion();
    }
    return this._target.quaternion;
  }

  _FindIntersections(pos) {
    const _IsAlive = (c) => {
      const h = c.entity.GetComponent('HealthComponent');
      if (!h) {
        return true;
      }
      return h._health > 0;
    };

    const grid = this.GetComponent('SpatialGridController');
    const nearby = grid.FindNearbyEntities(100).filter(e => _IsAlive(e));
    const collisions = [];

    for (let i = 0; i < nearby.length; ++i) {
      const e = nearby[i].entity;
      const d = ((pos.x - e._position.x) ** 2 + (pos.z - e._position.z) ** 2) ** 0.5;

      // HARDCODED
      if (d <= 8) {
        collisions.push(nearby[i].entity);
      }
    }
    return collisions;
  }

  _FindPlayer(pos) {
    const _IsAlivePlayer = (c) => {
      const h = c.entity.GetComponent('HealthComponent');
      if (h) {
        return false;
      }
      if (c.entity.Name === 'player') {
        return false;
      }
      // return h._health > 0;
    };

    const grid = this.GetComponent('SpatialGridController');
    const nearby = grid.FindNearbyEntities(200).filter(c => _IsAlivePlayer(c));

    if (nearby.length === 0) {
      return new THREE.Vector3(0, 0, 0);
    }

    const dir = this._parent._position.clone();
    dir.sub(nearby[0].entity._position);
    dir.y = 0.0;
    dir.normalize();

    return dir;
  }

  _UpdateAI(timeInSeconds) {
    const currentState = this._stateMachine._currentState;
    if (currentState.Name !== 'walk' &&
        currentState.Name !== 'run' &&
        currentState.Name !== 'idle') {
      return;
    }

    if (currentState.Name === 'death') {
      return;
    }

    if (currentState.Name === 'idle' ||
        currentState.Name === 'walk') {
      this._OnAIWalk(timeInSeconds);
    }
  }

  _OnAIWalk(timeInSeconds) {
    const dirToPlayer = this._FindPlayer();

    const velocity = this._velocity;
    const frameDecceleration = new THREE.Vector3(
        velocity.x * this._decceleration.x,
        velocity.y * this._decceleration.y,
        velocity.z * this._decceleration.z
    );
    frameDecceleration.multiplyScalar(timeInSeconds);
    frameDecceleration.z = Math.sign(frameDecceleration.z) * Math.min(
        Math.abs(frameDecceleration.z), Math.abs(velocity.z));

    velocity.add(frameDecceleration);

    const controlObject = this._target;
    // const _Q = new THREE.Quaternion();
    // const _A = new THREE.Vector3();
    const _R = controlObject.quaternion.clone();

    this._input._keys.forward = false;

    const acc = this._acceleration;
    if (dirToPlayer.length === 0) {
      return;
    }

    this._input._keys.forward = true;
    velocity.z += 0.7 * acc.z * timeInSeconds;

    const m = new THREE.Matrix4();
    m.lookAt(
        new THREE.Vector3(0, 0, 0),
        dirToPlayer,
        new THREE.Vector3(0, 1, 0));
    _R.setFromRotationMatrix(m);

    controlObject.quaternion.copy(_R);

    const oldPosition = new THREE.Vector3();
    oldPosition.copy(controlObject.position);

    const forward = new THREE.Vector3(0, 0, 1);
    forward.applyQuaternion(controlObject.quaternion);
    forward.normalize();

    const sideways = new THREE.Vector3(1, 0, 0);
    sideways.applyQuaternion(controlObject.quaternion);
    sideways.normalize();

    sideways.multiplyScalar(2*velocity.x * timeInSeconds);
    forward.multiplyScalar(2*velocity.z * timeInSeconds);

    const pos = controlObject.position.clone();
    pos.add(forward);
    pos.add(sideways);

    const collisions = this._FindIntersections(pos);
    if (collisions.length > 0) {
      this._input._keys.space = true;
      this._input._keys.forward = false;
      return;
    }

    controlObject.position.copy(pos);
    this._position.copy(pos);

    this._parent.SetPosition(this._position);
    this._parent.SetQuaternion(this._target.quaternion);
  }

  Update(timeInSeconds) {
    if (!this._stateMachine._currentState) {
      return;
    }

    this._input._keys.space = false;
    this._input._keys.forward = false;

    this._UpdateAI(timeInSeconds);

    this._stateMachine.Update(timeInSeconds, this._input);

    // HARDCODED
    if (this._stateMachine._currentState._action) {
      this.Broadcast({
        topic: 'player.action',
        action: this._stateMachine._currentState.Name,
        time: this._stateMachine._currentState._action.time,
      });
    }
    
    if (this._mixer) {
      this._mixer.update(timeInSeconds);
    }
  }
};

class NPCController4 extends Component {
  constructor(params) {
    super();
    this._Init(params);
  }

  _Init(params) {
    this._params = params;
    this._decceleration = new THREE.Vector3(-0.0005, -0.0001, -5.0);
    this._acceleration = new THREE.Vector3(1, 0.25, 40.0);
    this._velocity = new THREE.Vector3(0, 0, 0);
    this._position = new THREE.Vector3();

    this._animations = {};
    this._input = new AIInput();
    // FIXME
    this._stateMachine = new NPCFSM(new BasicCharacterControllerProxy(this._animations));

    this._LoadModels();
  }

  InitComponent() {
    this._RegisterHandler('health.death', (m) => { this._OnDeath(m); });
    this._RegisterHandler('update.position', (m) => { this._OnPosition(m); });
  }

  _OnDeath(msg) {
    this._stateMachine.SetState('death');
  }

  _OnPosition(m) {
    if (this._target) {
      this._target.position.copy(m.value);
      this._target.position.y = -20;
    }
  }

  _LoadModels() {
    const loader = new GLTFLoader();
    const draco = new DRACOLoader();
    // draco.setDecoderPath('https://cdn.jsdelivr.net/npm/three@0.118.1/examples/js/libs/draco/');
    draco.setDecoderPath('./draco/');
    loader.setDRACOLoader(draco);
    loader.setPath('./paleozoic-resources/glb/npc/');
    loader.load(this._params.resourceName, (glb) => {
      this._target = glb.scene;
      this._target.scale.set(2, 2, 2);
      this._params.scene.add(this._target);
      this._target.position.copy(this._parent._position);

      this._target.traverse(c => {
        c.castShadow = true;
        c.receiveShadow = true;
      });

      this._mixer = new THREE.AnimationMixer(this._target);

      const fbx = glb;

      const _FindAnim = (animName) => {
        for (let i = 0; i < fbx.animations.length; i++) {
          if (fbx.animations[i].name.includes(animName)) {
            const clip = fbx.animations[i];
            const action = this._mixer.clipAction(clip);
            return {
              clip: clip,
              action: action
            }
          }
        }
        return null;
      
      };

      this._animations['idle'] = _FindAnim('Swimming');
      this._animations['walk'] = _FindAnim('Swimming');
      this._animations['run'] = _FindAnim('Swimming');
      this._animations['attack'] = _FindAnim('Swimming');


      this._stateMachine.SetState('idle');
    });
  }

  get Position() {
    return this._position;
  }

  get Rotation() {
    if (!this._target) {
      return new THREE.Quaternion();
    }
    return this._target.quaternion;
  }

  _FindIntersections(pos) {
    const _IsAlive = (c) => {
      const h = c.entity.GetComponent('HealthComponent');
      if (!h) {
        return true;
      }
      return h._health > 0;
    };

    const grid = this.GetComponent('SpatialGridController');
    const nearby = grid.FindNearbyEntities(100).filter(e => _IsAlive(e));
    const collisions = [];

    for (let i = 0; i < nearby.length; ++i) {
      const e = nearby[i].entity;
      const d = ((pos.x - e._position.x) ** 2 + (pos.z - e._position.z) ** 2) ** 0.5;

      // HARDCODED
      if (d <= 25) {
        collisions.push(nearby[i].entity);
      }
    }
    return collisions;
  }

  _FindPlayer(pos) {
    const _IsAlivePlayer = (c) => {
      const h = c.entity.GetComponent('HealthComponent');
      if (!h) {
        // this.sound.pause();
        return false;
      }
      if (c.entity.Name !== 'player') {
        // this.sound.pause();
        return false;
      }
      return h._health > 0;
    };

    const grid = this.GetComponent('SpatialGridController');
    const nearby = grid.FindNearbyEntities(200).filter(c => _IsAlivePlayer(c));

    if (nearby.length === 0) {
      return new THREE.Vector3(0, 0, 0);
    }

    const dir = this._parent._position.clone();
    dir.sub(nearby[0].entity._position);
    dir.y = 0.0;
    dir.normalize();
    // setInterval(() => {
      // this.sound.play()
    // },
    // 3000)

    return dir;
  }

  _UpdateAI(timeInSeconds) {
    const currentState = this._stateMachine._currentState;
    if (currentState.Name !== 'walk' &&
        currentState.Name !== 'run' &&
        currentState.Name !== 'idle') {
      return;
    }

    if (currentState.Name === 'death') {
      return;
    }

    if (currentState.Name === 'idle' ||
        currentState.Name === 'walk') {
      this._OnAIWalk(timeInSeconds);
    }
  }

  _OnAIWalk(timeInSeconds) {
    const dirToPlayer = this._FindPlayer();

    const velocity = this._velocity;
    const frameDecceleration = new THREE.Vector3(
        velocity.x * this._decceleration.x,
        velocity.y * this._decceleration.y,
        velocity.z * this._decceleration.z
    );
    frameDecceleration.multiplyScalar(timeInSeconds);
    frameDecceleration.z = Math.sign(frameDecceleration.z) * Math.min(
        Math.abs(frameDecceleration.z), Math.abs(velocity.z));

    velocity.add(frameDecceleration);

    const controlObject = this._target;
    // const _Q = new THREE.Quaternion();
    // const _A = new THREE.Vector3();
    const _R = controlObject.quaternion.clone();

    this._input._keys.forward = false;

    const acc = this._acceleration;
    if (dirToPlayer.length === 0) {
      return;
    }

    this._input._keys.forward = true;
    velocity.z += acc.z * timeInSeconds * 1.5;

    const m = new THREE.Matrix4();
    m.lookAt(
        new THREE.Vector3(0, 0, 0),
        dirToPlayer,
        new THREE.Vector3(0, 1, 0));
    _R.setFromRotationMatrix(m);

    controlObject.quaternion.copy(_R);

    const oldPosition = new THREE.Vector3();
    oldPosition.copy(controlObject.position);

    const forward = new THREE.Vector3(0, 0, 1);
    forward.applyQuaternion(controlObject.quaternion);
    forward.normalize();

    const sideways = new THREE.Vector3(1, 0, 0);
    sideways.applyQuaternion(controlObject.quaternion);
    sideways.normalize();

    sideways.multiplyScalar(2*velocity.x * timeInSeconds);
    forward.multiplyScalar(2*velocity.z * timeInSeconds);

    const pos = controlObject.position.clone();
    pos.add(forward);
    pos.add(sideways);

    const collisions = this._FindIntersections(pos);
    if (collisions.length > 0) {
      this._input._keys.space = true;
      this._input._keys.forward = false;
      return;
    }

    controlObject.position.copy(pos);
    this._position.copy(pos);
    this._parent.SetPosition(this._position);
    this._parent.SetQuaternion(this._target.quaternion);
  }

  Update(timeInSeconds) {
    if (!this._stateMachine._currentState) {
      return;
    }

    this._input._keys.space = false;
    this._input._keys.forward = false;

    this._UpdateAI(timeInSeconds);

    this._stateMachine.Update(timeInSeconds, this._input);

    // HARDCODED
    if (this._stateMachine._currentState._action) {
      this.Broadcast({
        topic: 'player.action',
        action: this._stateMachine._currentState.Name,
        time: this._stateMachine._currentState._action.time,
      });
    }
    
    if (this._mixer) {
      this._mixer.update(timeInSeconds);
    }
  }
};



export { NPCController1, NPCController2, NPCController3, NPCController4 };