import Component from "./Component.mjs";

class BasicCharacterControllerInput extends Component {
    constructor(params) {
      super();
      this._params = params;
      this._Init();
    }
  
    _Init() {
      this._keys = {
        forward: false,
        backward: false,
        left: false,
        right: false,
        space: false,
        shift: false,
        spawn: false,
      };
      document.addEventListener('keydown', (e) => this._onKeyDown(e), false);
      document.addEventListener('keyup', (e) => this._onKeyUp(e), false);
    }
  
    _onKeyDown(event) {
      switch (event.keyCode) {
        case 87: // w or arrow up
          this._keys.forward = true;
          break;
        case 38: // w or arrow up
          this._keys.forward = true;
          break;
        case 65: // a or arrow left
          this._keys.left = true;
          break;
        case 37:
          this._keys.left = true;
          break;
        case 83: // s or arrow down
          this._keys.backward = true;
          break;
        case 40: // s or arrow down
          this._keys.backward = true;
          break;
        case 68: // d or arrow right
          this._keys.right = true;
          break;
        case 39: // d or arrow right
          this._keys.right = true;
          break;
        case 32: // SPACE
          this._keys.space = true;
          break;
        case 16: // SHIFT
          this._keys.shift = true;
          break;
        default:
          break;
      }
    }
  
    _onKeyUp(event) {
      switch(event.keyCode) {
        case 87: // w or arrow up
          this._keys.forward = false;
          break;
        case 38:
          this._keys.forward = false;
          break;
        case 65: // a or arrow left
          this._keys.left = false;
          break;
        case 37: // a or arrow left
          this._keys.left = false;
          break;
        case 83: // s or arrow down
          this._keys.backward = false;
          break;
        case 40: // s or arrow down
          this._keys.backward = false;
          break;
        case 68: // d or arrow right
          this._keys.right = false;
          break;
        case 39: // d or arrow right
          this._keys.right = false;
          break;
        case 32: // SPACE
          this._keys.space = false;
          break;
        case 16: // SHIFT
          this._keys.shift = false;
          break;
        default:
          break;
      }
    }
};

export default BasicCharacterControllerInput;