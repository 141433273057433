import * as THREE from "three"; // 'https://cdn.jsdelivr.net/npm/three@0.118/build/three.module.js';

class Entity {
  constructor() {
    this._name = null;
    this._components = {}; 

    this._position = new THREE.Vector3();
    this._rotation = new THREE.Quaternion();
    this._handlers = {};
    this._parent = null;
  }

  _RegisterHandler(n, h) {
    if (!(n in this._handlers)) {
      this._handlers[n] = [];
    }
    this._handlers[n].push(h);
  }

  SetParent(p) {
    this._parent = p;
  }

  SetName(n) {
    this._name = n;
  }

  get Name() {
    return this._name;
  }

  SetActive(b) {
    this._parent.SetActive(this, b);
  }

  AddComponent(component) {
    component.SetParent(this);
    this._components[component.constructor.name] = component;

    component.InitComponent();
  }

  GetComponent(constructorName) {
    return this._components[constructorName];
  }

  FindEntity(n) {
    return this._parent.Get(n);
  }

  Broadcast(msg) {
    if (!(msg.topic in this._handlers)) {
      return;
    }

    for (let curHandler of this._handlers[msg.topic]) {
      curHandler(msg);
    }
  }

  SetPosition(p) {
    this._position.copy(p);
    this.Broadcast({
        topic: 'update.position',
        value: this._position,
    });
  }

  SetQuaternion(r) {
    this._rotation.copy(r);
    this.Broadcast({
        topic: 'update.rotation',
        value: this._rotation,
    });
  }

  Update(timeElapsed) {
    for (let k in this._components) {
      this._components[k].Update(timeElapsed);
    }
  }
};

export default Entity;