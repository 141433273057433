import FiniteStateMachine from "./FiniteStateMachine.mjs";
import {  
  PlayerDeathState, PlayerAttackState, PlayerWalkState, PlayerIdleState,
  // PlayerWalkBackState, PlayerRunState,
} from "./PlayerState.mjs";

class NPCFSM extends FiniteStateMachine {
    constructor(proxy) {
      super();
      this._proxy = proxy;
      this._Init();
    } 
  
    _Init() {
      this._AddState('idle', PlayerIdleState);
      this._AddState('walk', PlayerWalkState);
      this._AddState('death', PlayerDeathState);
      this._AddState('attack', PlayerAttackState);
    }
};

export default NPCFSM;