import FiniteStateMachine from "./FiniteStateMachine.mjs";
import {  
  PlayerDeathState, PlayerAttackState, PlayerWalkState, PlayerIdleState,
  PlayerWalkBackState, PlayerRunState,
} from "./PlayerState.mjs";

class CharacterFSM extends FiniteStateMachine {
    constructor(proxy) {
      super();
      this._proxy = proxy;
      this._Init();
    }
  
    _Init() {
      this._AddState('idle', PlayerIdleState);
      this._AddState('walk', PlayerWalkState);
      this._AddState('run', PlayerRunState);
      this._AddState('walk back', PlayerWalkBackState);
      this._AddState('attack', PlayerAttackState);
      this._AddState('death', PlayerDeathState);
    }
};

export default CharacterFSM;