import * as THREE from "three"; // 'https://cdn.jsdelivr.net/npm/three@0.118/build/three.module.js';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"; // 'https://cdn.jsdelivr.net/npm/three@0.118.1/examples/jsm/loaders/GLTFLoader.js';

import Entity from "./Entity.mjs";
import Joystick from "./Joystick.mjs";
import JoystickController from './JoystickController.mjs';
import BasicCharacterController from './BasicCharacterController.mjs';
import BasicCharacterControllerInput from "./BasicCharacterControllerInput.mjs";
import ThirdPersonCamera from "./ThirdPersonCamera.mjs";
import EntityManager from "./EntityManager.mjs";
import Calculate from "./Calculate.mjs";
import AttackController from "./AttackController.mjs";
// import AttackController_T from "./AttackControllerT.mjs";
// import HealthBar from "./HealthBar.mjs";
import HealthComponent from "./HealthComponent.mjs";
import SpatialGridController from "./SpatialGridController.mjs";
import SpatialHashGrid from "./SpatialHashGrid.mjs";
// import ParticleSystem from "./ParticleSystem.mjs";
import { NPCController1 } from "./NPCController.mjs"; // , NPCController2, NPCController3, NPCController4

class PaleozoicEra {
    constructor() {
      this._Initialize();
    }
  
    _Initialize() {
      this._threejs = new THREE.WebGLRenderer({
        antialias: true,
      });
      this._threejs.outputEncoding = THREE.sRGBEncoding;
      // this._threejs.gammaFactor = 2.2;
      this._threejs.shadowMap.enabled = true;
      this._threejs.shadowMap.type = THREE.PCFSoftShadowMap;
      this._threejs.setPixelRatio(window.devicePixelRatio);
      this._threejs.setSize(window.innerWidth, window.innerHeight);
      this._threejs.domElement.id = 'threejs';
      
  
      document.querySelector("#container").appendChild(this._threejs.domElement);
  
      window.addEventListener('resize', () => {
        this._OnWindowResize();
      }, false);
  
      const fov = 60;
      const aspect = 1920 / 1080;
      const near = 1.0;
      const far = 10000.0;
      // const game = this;
      this._camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
      this._camera.position.set(25, 70, 100);
  
      this._scene = new THREE.Scene();
      this._scene.background = new THREE.Color(0xFFFFFF);
  
      let light = new THREE.AmbientLight(0x404040, 2);

      let light2 = new THREE.DirectionalLight(0xFFFFFF, 0.05);
      light2.position.set(0, 80, 0);
      light2.target.position.set(0, 3, 0);
      light2.castShadow = true;
      light2.shadow.bias = -0.001;
      light2.shadow.mapSize.width = 250;
      light2.shadow.mapSize.height = 250;
      light2.shadow.camera.near = 0.1;
      light2.shadow.camera.far = 1000.0;
      light2.shadow.camera.left = 250;
      light2.shadow.camera.right = -250;
      light2.shadow.camera.top = 250;
      light2.shadow.camera.bottom = -250;

      this._scene.add(light);  
      // this._scene.add(light2);
      this._sun = light;

      const startBtn = document.querySelector("#startBtn");
      const timeDisplay = document.querySelector("#time-display");
      const progressBarContainer = document.querySelector("#progress-bar-container");
      const progressBar = document.querySelector("#progress-bar");
      const label = document.querySelector("#label");
      const loadingScreen = document.querySelector("#loading");
      const instructionImageContainer = document.querySelector("#instruction-image-container");
      
      const loadingManager = new THREE.LoadingManager();
      loadingManager.onProgress = function(url, loaded, total) {
        progressBar.value = (loaded/total) * 100;
      }
      
      loadingManager.onLoad = function() {
        startBtn.style.display = "block";
        progressBar.style.display = "none";
        label.style.display = "none";
        loadingScreen.style.display = "none";
        instructionImageContainer.style.display = "flex";
      }

      let intervalId;

      startBtn.addEventListener("click", () => {   
      if (paused) {
      paused = false;
      startTime = Date.now() - elapsedTime;
      intervalId = setInterval(updateTime(), 1000);
      }

      startBtn.style.display='none';
      progressBarContainer.style.display = 'none';
      instructionImageContainer.style.visibility = "hidden";

      setTimeout(()=> {
        this._LoadNPC();
      }, 10000); // 10초가 지나면 추가 NPC 로딩!

      const listener = new THREE.AudioListener();
      this._scene.add(listener)
      const sound = new THREE.Audio(listener);

      const sLoader = new THREE.AudioLoader();
      sLoader.load('./paleozoic-resources/sound/underwater.mp3', (buffer) => {
      sound.setBuffer(buffer);
      sound.setLoop(true);
      sound.setVolume(1);
      sound.play();
      })
    });

      let startTime = 0;
      let elapsedTime = 0;
      // let currentTime = 0;
      let paused = true;
      // let intervalId;
      let hrs = 0;
      let mins = 0;
      let secs = 0;

    function updateTime(){
      elapsedTime = Date.now() - startTime;

    secs = Math.floor((elapsedTime / 1000) % 60);
    mins = Math.floor((elapsedTime / (1000 * 60)) % 60);
    hrs = Math.floor((elapsedTime / (1000 * 60 * 60)) % 60);

    secs = pad(secs);
    mins = pad(mins);
    hrs = pad(hrs);

    timeDisplay.textContent = `${hrs}:${mins}:${secs}`;

    function pad(unit){
        return (("0") + unit).length > 2 ? unit : "0" + unit
    }
  }

    const loader2 = new GLTFLoader(loadingManager);
    loader2.setPath('./paleozoic-resources/glb/');
    loader2.load('paleozoic_hdri_glb-1.glb', (glb) => {
      this._target = glb.scene;

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        this._target.scale.set(12000, 12000, 12000);
        this._target.position.x = -7810;
        this._target.position.y = -1400;
        this._target.position.z = 0;
      }
      else{
        this._target.scale.set(8000, 8000, 8000);
        this._target.position.x = -5200;
        this._target.position.y = -900;
        this._target.position.z = 0;
      }

      this._scene.add(this._target);

      this._target.traverse(c => {
        c.castShadow = true;
        c.receiveShadow = true;
      });
    })

      this._entityManager = new EntityManager();
      this._grid = new SpatialHashGrid(
          [[-1000, -1000], [1000, 1000]], [100, 100]);
    
      this._LoadPlayer(loadingManager);
      this._LoadNPC(loadingManager);
      // this._LoadNPC_2(loadingManager);
      // this._LoadNPC_3(loadingManager);
      // this._LoadNPC_4(loadingManager);
      this._previousRAF = null;
      this._RAF();
    }

    _LoadPlayer() {
        const params = {
            camera: this._camera,
            scene: this._scene,
          };
        const player = new Entity();

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
          player.AddComponent(new Joystick(params));
          player.AddComponent(new JoystickController(params));
        } 
        else {
          player.AddComponent(new BasicCharacterControllerInput(params));
          player.AddComponent(new BasicCharacterController(params));
        };

        player.AddComponent(new HealthComponent({
          updateUI: true,
          health: 100,
          maxHealth: 100,
          strength: 50,
          camera: this._camera,
          scene: this._scene,
        }));

        // 현재 버전 연동 X
        // player.AddComponent(new HealthBar({
        //   parent: this._scene,
        //   camera: this._camera,
        // }));
       
        player.AddComponent(
          new SpatialGridController({grid: this._grid}));
        player.AddComponent(new AttackController({timing: 0.7}));
        this._entityManager.Add(player, 'player');

        const camera = new Entity();
        camera.AddComponent(
            new ThirdPersonCamera({
                camera: this._camera,
                target: this._entityManager.Get('player')}));
        this._entityManager.Add(camera, 'player-camera');

    }

    _LoadNPC() {
      for (let i = 0; i < 4; ++i) {
      // for (let i = 0; i < number; ++i) {
        const monsters = [
          {
            resourceName: 'helicoprion-1.glb',
            resourceTexture: 'Helicoprion_Skin_Default.png',
          },
        ];
        const m = monsters[Calculate.rand_int(0, monsters.length - 1)];
  
        const npc = new Entity();
        // const startBtn = document.querySelector("#startBtn");
        npc.AddComponent(
            new SpatialGridController({grid: this._grid}));
        npc.AddComponent(new NPCController1({
          camera: this._camera,
          scene: this._scene,
          resourceName: m.resourceName,
          resourceTexture: m.resourceTexture,
        }));
        npc.AddComponent(new AttackController({timing: 0.5}));
        npc.SetPosition(new THREE.Vector3(
            (Math.random() * 2 - 1) * -200,
            0,
            (Math.random() * 2 - 1) * 200));
        //  startBtn.addEventListener("click", () => {
        //   this._entityManager.Add(npc);
        // });
        this._entityManager.Add(npc);
      }
    }


    // _LoadNPC_2() {
    //   for (let i = 0; i < 3; ++i) {
    //     const monsters = [
    //       {
    //         resourceName: 'dunkleosteus-3.glb',
    //         resourceTexture: 'BaseColor(1).png',
    //       },
    //     ];
    //     const m = monsters[Calculate.rand_int(0, monsters.length - 1)];
  
    //     const npc = new Entity();
    //     const startBtn = document.querySelector("#startBtn");
    //     npc.AddComponent(
    //         new SpatialGridController({grid: this._grid}));
    //     npc.AddComponent(new NPCController2({
    //       camera: this._camera,
    //       scene: this._scene,
    //       resourceName: m.resourceName,
    //       resourceTexture: m.resourceTexture,
    //     }));
    //     npc.AddComponent(new AttackController_T({timing: 0.5}));
    //     npc.SetPosition(new THREE.Vector3(
    //         (Math.random() * 2 - 1) * -150,
    //         0,
    //         (Math.random() * 2 - 1) * 150));
    //      startBtn.addEventListener("click", () => {
    //       this._entityManager.Add(npc);
    //     });
    //   }
    // }
  
    // _LoadNPC_3() {
    //   for (let i = 0; i < 4; ++i) {
    //     const monsters = [
    //       {
    //         resourceName: 'anomalocaris.fbx',
    //         resourceTexture: 'AnomalocarisYellow.png',
    //       },
    //     ]; 
    //     const m = monsters[Calculate.rand_int(0, monsters.length - 1)];
  
    //     const npc = new Entity();
    //     const startBtn = document.querySelector("#startBtn");
    //     npc.AddComponent(
    //         new SpatialGridController({grid: this._grid}));
    //     npc.AddComponent(new NPCController3({
    //       camera: this._camera,
    //       scene: this._scene,
    //       resourceName: m.resourceName,
    //       resourceTexture: m.resourceTexture,
    //     }));
    //     npc.AddComponent(new AttackController({timing: 0.5}));
    //     this._particles = new ParticleSystem({
    //       parent: this._scene,
    //       camera: this._camera,
    //     });
    //     npc.SetPosition(new THREE.Vector3(
    //         (Math.random() * 2 - 1) * -200,
    //         -120,
    //         (Math.random() * 2 - 1) * 200));
    //      startBtn.addEventListener("click", () => {
    //       this._entityManager.Add(npc);
    //     });
    //   }
    // }

    // _LoadNPC_4() {
    //   for (let i = 0; i < 3; ++i) {
    //     const monsters = [
    //       {
    //         resourceName: 'thelodus-1.glb',
    //       },
    //     ];
    //     const m = monsters[Calculate.rand_int(0, monsters.length - 1)];
  
    //     const npc = new Entity();
    //     const startBtn = document.querySelector("#startBtn");
    //     npc.AddComponent(
    //         new SpatialGridController({grid: this._grid}));
    //     npc.AddComponent(new NPCController4({
    //       camera: this._camera,
    //       scene: this._scene,
    //       resourceName: m.resourceName,
    //       resourceTexture: m.resourceTexture,
    //     }));
    //     npc.AddComponent(new AttackController({timing: 0.5}));
    //     npc.SetPosition(new THREE.Vector3(
    //         (Math.random() * 2 - 1) * -150,
    //         -20,
    //         (Math.random() * 2 - 1) * 150));
    //      startBtn.addEventListener("click", () => {
    //       this._entityManager.Add(npc);
    //     });
    //   }
    // }

    _OnWindowResize() {
      this._camera.aspect = window.innerWidth / window.innerHeight;
      this._camera.updateProjectionMatrix();
      this._threejs.setSize(window.innerWidth, window.innerHeight);
    }
  
    _RAF() {
      requestAnimationFrame((t) => {
        if (this._previousRAF === null) {
          this._previousRAF = t;
        }
  
        this._RAF();
  
        this._threejs.render(this._scene, this._camera);
        this._Step(t - this._previousRAF);
        this._previousRAF = t;
      });
    }
  
    _Step(timeElapsed) {
      const timeElapsedS = Math.min(1.0 / 30.0, timeElapsed * 0.001);
  
    //   this._UpdateSun();
      // this._particles?.Step(timeElapsedS);
      this._entityManager?.Update(timeElapsedS);
    }
}

export default PaleozoicEra;