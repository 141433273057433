import * as THREE from "three"; // 'https://cdn.jsdelivr.net/npm/three@0.118/build/three.module.js';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"; // 'https://cdn.jsdelivr.net/npm/three@0.118.1/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js"; 
import Component from "./Component.mjs";
import CharacterFSM from "./CharacterFSM.mjs";
import BasicCharacterControllerProxy from "./BasicCharacterControllerProxy.mjs";
  

class BasicCharacterController extends Component {
constructor(params) {
    super();
    this._Init(params);
}

_Init(params) {
    this._params = params;
    this._decceleration = new THREE.Vector3(-0.0005, -0.0001, -5.0);
    this._acceleration = new THREE.Vector3(1, 0.125, 50.0);
    this._velocity = new THREE.Vector3(0, 0, 0);
    this._position = new THREE.Vector3();

    this._animations = {};
    this._stateMachine = new CharacterFSM(
        new BasicCharacterControllerProxy(this._animations));

    this._LoadModels();
}

InitComponent() {
    this._RegisterHandler('health.death', (m) => { this._OnDeath(m); });
}

_OnDeath(msg) {
    this._stateMachine.SetState('death');
}

_LoadModels() {
    const loader = new GLTFLoader();
    const draco = new DRACOLoader(); 
    // draco.setDecoderPath('https://cdn.jsdelivr.net/npm/three@0.118.1/examples/js/libs/draco/');
    draco.setDecoderPath('./draco/');
    loader.setDRACOLoader(draco);
    loader.setPath('./paleozoic-resources/glb/npc/');
    loader.load('thelodus-1.glb', (glb) => {
    this._target = glb.scene;
    this._target.scale.set(3, 3, 3);
    this._params.scene.add(this._target);
    this._target.position.copy(this._parent._position);

    this._target.traverse(c => {
        c.castShadow = true;
        c.receiveShadow = true;
    });

    this._mixer = new THREE.AnimationMixer(this._target);

    const fbx = glb;

    const _FindAnim = (animName) => {
        for (let i = 0; i < fbx.animations.length; i++) {
        if (fbx.animations[i].name.includes(animName)) {
            const clip = fbx.animations[i];
            const action = this._mixer.clipAction(clip);
            return {
            clip: clip,
            action: action
            }
        }
        }
        return null;
    
    };

    this._animations['idle'] = _FindAnim('Swimming');
    this._animations['walk'] = _FindAnim('Swimming');
    this._animations['run'] = _FindAnim('Swimming');
    this._animations['attack'] = _FindAnim('Swimming');
    this._animations['death'] = _FindAnim('Swimming');

    this._stateMachine.SetState('idle');
    });
}

_FindIntersections(pos) {
    const _IsAlive = (c) => {
    const h = c.entity.GetComponent('HealthComponent');
    if (!h) {
        return true;
    }
    return h._health > 0;
    };

    const grid = this.GetComponent('SpatialGridController');
    const nearby = grid.FindNearbyEntities(5).filter(e => _IsAlive(e));
    const collisions = [];

    for (let i = 0; i < nearby.length; ++i) {
    const e = nearby[i].entity;
    const d = ((pos.x - e._position.x) ** 2 + (pos.z - e._position.z) ** 2) ** 0.5;

    // HARDCODED
    if (d <= 4) {
        collisions.push(nearby[i].entity);
    }
    }
    return collisions;
}

Update(timeInSeconds) {
    if (!this._stateMachine._currentState) {
    return;
    }

    const input = this.GetComponent('BasicCharacterControllerInput');
    this._stateMachine.Update(timeInSeconds, input);

    if (this._mixer) {
    this._mixer.update(timeInSeconds);
    }

    // HARDCODED
    if (this._stateMachine._currentState._action) {
    this.Broadcast({
        topic: 'player.action',
        action: this._stateMachine._currentState.Name,
        time: this._stateMachine._currentState._action.time,
    });
    }

    const currentState = this._stateMachine._currentState;
    if (currentState.Name !== 'walk' &&
        currentState.Name !== 'run' &&
        currentState.Name !== 'idle') {
    return;
    }

    const velocity = this._velocity;
    const frameDecceleration = new THREE.Vector3(
        velocity.x * this._decceleration.x,
        velocity.y * this._decceleration.y,
        velocity.z * this._decceleration.z
    );
    frameDecceleration.multiplyScalar(timeInSeconds);
    frameDecceleration.z = Math.sign(frameDecceleration.z) * Math.min(
        Math.abs(frameDecceleration.z), Math.abs(velocity.z));

    velocity.add(frameDecceleration);

    const controlObject = this._target;
    const _Q = new THREE.Quaternion();
    const _A = new THREE.Vector3();
    const _R = controlObject.quaternion.clone();

    const acc = this._acceleration.clone();
    // if (input._keys.shift) {
    //   acc.multiplyScalar(2.0);
    // }
    if (input._keys.forward) {
    velocity.z += 2.0*acc.z * timeInSeconds;
    }
    if (input._keys.backward) {
    velocity.z -= 2.0*acc.z * timeInSeconds;
    }
    if (input._keys.left) {
    _A.set(0, 1, 0);
    _Q.setFromAxisAngle(_A, 2.0 * Math.PI * timeInSeconds * this._acceleration.y);
    _R.multiply(_Q);
    }
    if (input._keys.right) {
    _A.set(0, 1, 0);
    _Q.setFromAxisAngle(_A, 2.0 * -Math.PI * timeInSeconds * this._acceleration.y);
    _R.multiply(_Q);
    }

    controlObject.quaternion.copy(_R);

    const oldPosition = new THREE.Vector3();
    oldPosition.copy(controlObject.position);

    const forward = new THREE.Vector3(0, 0, 1);
    forward.applyQuaternion(controlObject.quaternion);
    forward.normalize();

    const sideways = new THREE.Vector3(1, 0, 0);
    sideways.applyQuaternion(controlObject.quaternion);
    sideways.normalize();

    sideways.multiplyScalar(velocity.x * timeInSeconds);
    forward.multiplyScalar(velocity.z * timeInSeconds);

    const pos = controlObject.position.clone();
    
    pos.add(forward);
    pos.add(sideways);

    // console.log(pos)
    const map = new THREE.Vector3(Math.abs(100), 0, Math.abs(100))

    const collisions = this._FindIntersections(map);
    if (collisions.length < 0) {
    return;
    }

    controlObject.position.copy(pos);
    this._position.copy(pos);

    this._parent.SetPosition(this._position);
    this._parent.SetQuaternion(this._target.quaternion);
}
};

export default BasicCharacterController;